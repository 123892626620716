import React from 'react'
import Layout from '../../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from '../../pages/e-books.module.css'

const AttractionPage = () => {
  return (
    <Layout>
      <section className={`${styles.jumbo} jumbo h-52 sm:h-72`}>
        <div className="container">
          <h1 className="text-5xl sm:text-8xl text-red-800">Attraction</h1>
        </div>
      </section>
      <section className="content pt-16">
        <div className="container mx-auto px-4 !max-w-[100%]">
          <div className="flex flex-col md:flex-row gap-12">
            <div className="w-full md:w-1/4">
              <StaticImage 
                src="../../images/attraction-cover.jpg"
                alt="Attraction Book Cover"
                className="w-full rounded-lg"
              />
            </div>
            <div className="w-full md:w-1/2">
              <div className="flex flex-col md:flex-row justify-between items-center gap-4 mb-4">
                <h2 className="text-3xl font-bold text-gray-900 !mt-0">The Science of Keeping Her Hooked</h2>
                <a 
                  href="https://marciancx.gumroad.com/l/attraction"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block bg-red-800 text-white px-4 py-4 rounded-lg hover:bg-red-900 transition-colors whitespace-nowrap"
                >
                  Get your FREE copy!
                </a>
              </div>
              <div className="space-y-4">
                <p className="text-gray-700 text-base">
                  Most men think attraction is about being nice, stable, and always available—but they're wrong. 
                  Women aren't drawn to comfort alone; they crave strength, purpose, and leadership.
                </p>
                <p className="text-gray-700 text-base">
                  This short, no-nonsense guide breaks down the three key steps to keeping a woman emotionally 
                  hooked—without playing games, chasing, or sacrificing your mission.
                </p>
                <p className="text-gray-700 text-base">
                  Step 1: Become Unpredictable – Keep her engaged with challenge, excitement, and novelty.<br />
                  Step 2: Stop Making Her Your World – Lead your life, and she will follow.<br />
                  Step 3: Create Tension & Distance – Space and anticipation fuel long-term desire.
                </p>
                <p className="text-gray-700 text-base mb-8">
                  If you want to command respect, radiate presence, and keep a woman's attention effortlessly, 
                  this is the playbook. Master yourself, and attraction follows.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AttractionPage 